import _ from 'lodash'
import * as $rdf from 'rdflib'
import moment from 'moment'
import Graph from '@/rdf/Graph'
import {
  DASH, DCT, DCAT, FDPO, METACAT, RDFS, XSD, EFO,
} from '@/rdf/namespaces'
import rdfUtils from '@/rdf/utils'
import config from '@/config'
import fieldUtils from '@/components/ShaclForm/fieldUtils'
import valueUtils from '@/components/ShaclForm/valueUtils'

function field(label, input, extra = {}) {
  if (typeof input !== 'object') {
    return {
      label,
      value: input,
      ...extra,
    }
  }

  if (Array.isArray(input)) {
    return {
      label,
      items: input,
      ...extra,
    }
  }

  return {
    label,
    value: input.label,
    uri: input.uri,
    ...extra,
  }
}

function dateField(label, input, extra = {}) {
  return field(label, moment(input).format(config.dateFormat), extra)
}

function itemFromPath(path) {
  if (!path) return null

  return {
    label: rdfUtils.pathTerm(path),
    uri: path,
  }
}

function commonMetadata(graph: Graph) {
  const metadataGroups = []

  const conformsTo = graph.findAll(DCT('conformsTo'))
  if (conformsTo.length > 0) {
    const data = conformsTo.map((uri) => {
      const label = graph.findOne(RDFS('label'), {
        subject: $rdf.namedNode(`${uri}`),
      })

      return {
        label: label || rdfUtils.pathTerm(`${uri}`),
        uri: uri.replace(config.persistentURL(), config.clientURL),
        resolved: true,
      }
    })
    metadataGroups.push({ fields: [field('Conforms to', data)] })
  }

  const abstract = graph.findOne(DCT('abstract'))
  if (abstract) {
    const data = abstract
    metadataGroups.push({ fields: [field('Abstract', data)] })
  }

  const temporalCoverage = graph.findAll(DCT('temporal'))
  if (temporalCoverage.length > 0) {
    const data = temporalCoverage.map((uri) => {
      const start = graph.findOne(DCAT('startDate'), {
        subject: $rdf.blankNode(`${uri}`),
      })
      const end = graph.findOne(DCAT('endDate'), {
        subject: $rdf.blankNode(`${uri}`),
      })
      let temporalCoverageString = ''
      if (start && end) {
        temporalCoverageString = `${start} - ${end}`
      }
      const comment = graph.findOne(RDFS('comment'), {
        subject: $rdf.blankNode(`${uri}`),
      })
      if (comment) {
        temporalCoverageString += ` [${comment}]`
      }

      return {
        label: temporalCoverageString,
        uri: null,
        resolved: true,
      }
    })
    metadataGroups.push({ fields: [field('Temporal Coverage', data)] })
  }

  const principalInvestigator = graph.findAll(EFO('0009736'))
  if (principalInvestigator.length > 0) {
    const data = principalInvestigator.map((uri) => ({
      label: uri,
      uri: null,
      resolved: true,
    }))
    metadataGroups.push({ fields: [field('Principal Investigator', data)] })
  }

  const contactPoint = graph.findAll(DCAT('contactPoint'))
  if (contactPoint.length > 0) {
    const data = contactPoint.map((uri) => ({
      label: uri,
      uri: null,
      resolved: true,
    }))
    metadataGroups.push({ fields: [field('Contact Point', data)] })
  }

  const source = graph.findAll(DCT('source'))
  if (source.length > 0) {
    const data = source.map((uri) => {
      let label = uri
      if (uri.startsWith('http')) {
        label = graph.findOne(RDFS('label'), {
          subject: $rdf.namedNode(`${uri}`),
        })
      }
      return {
        label,
        uri: uri.startsWith('http') ? uri : null,
        resolved: true,
      }
    })
    metadataGroups.push({ fields: [field('Source(s)', data)] })
  }

  const publication = graph.findAll(METACAT('hasPublication'))
  if (publication.length > 0) {
    const data = publication.map((uri) => ({
      label: uri,
      uri,
      resolved: true,
    }))
    metadataGroups.push({ fields: [field('Publication', data)] })
  }

  const metacatPropertiesMap = new Map<string, string>()
  metacatPropertiesMap.set('hasInclusionCriteria', 'Inclusion Criteria')
  metacatPropertiesMap.set('hasExclusionCriteria', 'Exclusion Criteria')
  metacatPropertiesMap.set('hasAgeRange', 'Age Range')
  metacatPropertiesMap.set('hasDataProvider', 'Data Provider')
  metacatPropertiesMap.set('hasAdditionalData', 'Additional Data')
  metacatPropertiesMap.set('hasDataUseRestriction', 'Data Use Restriction')
  const multivaluedProperties = new Set<string>(['hasDataProvider', 'hasAdditionalData', 'hasInclusionCriteria', 'hasExclusionCriteria', 'hasDataUseRestriction'])
  const literalProperties = new Set<string>(['hasAgeRange'])

  Array.from(metacatPropertiesMap.keys()).forEach((key) => {
    const value = metacatPropertiesMap.get(key)
    if (multivaluedProperties.has(key)) {
      const propertyValues = graph.findAll(METACAT(key))
      if (propertyValues.length > 0) {
        const data = propertyValues.map((propValue) => {
          let valueLabel = propValue
          if (!literalProperties.has(propValue)) {
            const label = graph.findOne(RDFS('label'), {
              subject: $rdf.blankNode(`${propValue}`),
            })
            if (label) {
              valueLabel = label
            }
          }
          return {
            label: valueLabel,
            url: null,
            resolved: true,
          }
        })
        metadataGroups.push({ fields: [field(value, data)] })
      }
    } else {
      const propertyValue = graph.findOne(METACAT(key))
      if (propertyValue) {
        const label = graph.findOne(RDFS('label'), {
          subject: $rdf.blankNode(`${propertyValue}`),
        })
        const data = {
          label: label || propertyValue,
          url: null,
          resolved: false,
        }
        metadataGroups.push({ fields: [field(value, data)] })
      }
    }
  })
  return metadataGroups
}

function wrapShaclValue(fieldConfig, value) {
  if (!value) {
    return null
  }

  switch (fieldConfig.viewer) {
    case DASH('LabelViewer').value:
      return itemFromPath(value)
    case DASH('URIViewer').value:
      return { label: value, uri: value }
    default:
      if (fieldConfig.datatype === XSD('dateTime').value) {
        return { label: moment(value).format(config.dateFormat) }
      }
      if (fieldConfig.datatype === XSD('boolean').value) {
        if (valueUtils.isTrue(value)) return { label: 'TRUE' }
        if (valueUtils.isFalse(value)) return { label: 'FALSE' }
      }
      return { label: value }
  }
}

function getShaclValue(graph: Graph, fieldConfig) {
  if (fieldConfig.maxCount === 1) {
    const value = graph.findOne($rdf.namedNode(fieldConfig.path))
    return wrapShaclValue(fieldConfig, value)
  }

  const values = graph.findAll($rdf.namedNode(fieldConfig.path))
  return values.map((v) => wrapShaclValue(fieldConfig, v)).filter((v) => v !== null)
}

function fromShaclField(graph: Graph, fieldConfig) {
  const name = fieldUtils.getName(fieldConfig)
  const value = getShaclValue(graph, fieldConfig)

  if (!value || _.isEmpty(value)) {
    return null
  }

  return field(name, getShaclValue(graph, fieldConfig))
}

export default {
  field,
  dateField,
  commonMetadata,
  itemFromPath,
  fromShaclField,
}
