













































import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'
import config from '@/config'
import Separator from '../Separator/index.vue'
import VersionInfoTable from '../VersionInfoTable/index.vue'

@Component({ components: { Separator, VersionInfoTable } })
export default class FdpFooter extends Vue {
  info: any = { version: '', builtAt: '' }

  get appTitle() {
    return config.appTitle()
  }

  created() {
    this.fetchData()
  }

  async fetchData(): Promise<void> {
    const response = await api.info.getInfo()
    this.info = response.data
  }
}
